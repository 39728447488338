<template>
  <section class="w-full min-h-[1000px] secondary-gradient py-16">
    <div class="container mx-auto flex flex-col gap-y-8">
      <FancyBadge label="Case Studies"></FancyBadge>
      <div class="flex flex-1 justify-between py-4 md:flex-row flex-col gap-4">
        <h3 class="text-header-xl font-bold max-w-[50rem]">
          {{ gallerySection.title }}
        </h3>
        <Button
          :aria-label="gallerySection.firstButton"
          variant="outline"
          class="font-bold text-p-sm bg-transparent text-primary flex gap-2 uppercase mt-auto w-fit"
        >
          {{ gallerySection.firstButton }}
          <Icon icon="mdi-arrow-right" class="text-xl"></Icon>
        </Button>
      </div>
      <ProductSummaryCard
        v-for="item in gallerySection.items"
        :key="item.title"
        v-bind="item"
      ></ProductSummaryCard>

      <div class="flex justify-center items-center gap-8 pt-4">
        <span>{{ gallerySection.outro }}</span>
        <Button
          @click="openModal"
          :aria-label="gallerySection.lastButton"
          class="font-bold primary-gradient flex gap-2 uppercase px-10 text-p-sm w-fit text-white"
        >
          {{ gallerySection.lastButton }}
          <Icon icon="mdi-arrow-right" class="text-xl"></Icon>
        </Button>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue";
import { gallerySection } from "~/lib/constants";
import { useModal } from "vue-final-modal";
import ContactModal from "~/components/global/ContactModal.vue";

const openModal = async () => {
  const modal = useModal({
    component: ContactModal,
  });
  await modal.open();
};
</script>

<style scoped></style>
